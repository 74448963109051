@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;600;700&family=Inter:wght@400;600;700&display=swap");
@import "app/activar.scss";

body {
  background-color: #FFFFFF !important;
  font-family: "Inter", sans-serif !important;
}
.container__quote {
  margin-top: 150px;
  padding: 50px 15px 200px;
}
.form-control,
.form-select {
  padding: 10px 0.75rem !important;
  background-color: #FFFFFF !important;
}
.form-control:focus {
  border-color: $blue !important;
  box-shadow: none !important;
}
/* AUTOCOMPLETE */
.ng-autocomplete {
  width: 100% !important;
  background-color: #FFFFFF !important;
  &.is-invalid {
    .autocomplete-container {
      width: 90%;
    }
  }
}

.autocomplete-container .input-container input {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: 0;
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  padding: 0 !important;
  line-height: 40px;
  height: auto !important;
}
.autocomplete-container {
  box-shadow: none !important;
  position: relative;
  overflow: visible;
  height: auto !important;
}
.autocomplete-control {
  padding: 2px 10px !important;
}
